@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import "./style.scss";
@import "./training-setup.scss";
@import "./training-queue.scss";
@import "./modal-fixes.scss";

html,
body,
#root,
:root {
    --background-dark: #1f2430;
    --background-darker: #222730;
    --background-darkest: #1c1d27;
    --background-darkestest: #090b11;
    --text-primary: #cccac2;
    --text-gentle: #b6b6b3;
    --text-muted: #626b7d; // #707a8c;
    --accent-primary: #ffcc66;
    --accent-success: #81dc5a;
    --accent-red: #dc5a5a;
    --accent-primary-dim: #cea248;
    --accent-primary-dimmer: #a2803c;
    --accent-primary-dimmest: #ffcc6653;
    --border: #171b24;

    background-color: var(--background-dark);
    color: var(--text-primary);
}

body {
    margin: 0;
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


body,
html,
#root,
.main {
    height: 100%;
    min-height: 100%;
}