.inf-queue-card .ant-card-body {
    padding: 0 !important;
    border-radius: 0 !important;
}

.poepen svg {
    font-size: 24px !important;
}

.caption-attachments {
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-bottom: 4px;

    .caption-attachment {
        white-space: pre;
        background-color: #ffffff24;
        border-radius: 4px;
        border: 1px solid #ffffff3b;
        padding: 2px 6px;
        font-size: 13px !important;
        color: #bbbbbb;
        min-width: 8px;

        &.overridden {
            background-color: #41b8134b;
            color: white;
        }
    }
}

.inference-prop-label {
    opacity: 0.6;
    text-transform: uppercase;
    font-size: 11px !important;
    line-height: 11px;
    margin-bottom: 2px;
    margin-left: 3px;
    font-family: monospace;
}

.inf-queue {
    display: flex;
    flex-direction: column;
    // row-gap: 4px;

    .inf-queue-item {
        display: flex;
        flex-direction: row;
        column-gap: 2px;
        min-height: 24px;
        padding: 4px 4px;
        background-color: #00000070;
        border-top: 1px solid rgba(255, 255, 255, 0.071);
        cursor: pointer;

        &:hover {
            background-color: #00000010;
        }

        &:nth-child(odd) {
            background-color: #000000A0;

            &:hover {
                background-color: #00000030;
            }
        }

        .inf-queue-item-prefix {
            flex: 0 0 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            .ant-tag {
                margin-inline-end: 0 !important;
            }
        }

        .inf-queue-steps {
            //
        }

        .inf-queue-cfg {
            //
        }

        .inf-queue-prompt {
            //
        }
    }
}


.lora-weight-slider .ant-slider {
    margin: 3px 5px;
}

.active-lora {
    .lora-thumbnail {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.lora-dikke-rukgroep {
    border: 2px solid var(--accent-primary);
    background-color: color-mix(in srgb, var(--accent-primary), transparent 90%); // rgba(var(--accent-primary), 90%);
    border-radius: 4px;
    padding: 2px;
    padding-top: 6px;
}

.lora-group-expanded .lora-wrapper {
    border: 2px solid var(--accent-primary);
    background-color: color-mix(in srgb, var(--accent-primary), transparent 90%); // rgba(var(--accent-primary), 90%);
    border-radius: 4px;
}

.lora-wrapper.active {
    border: 2px solid var(--accent-success);
    background-color: color-mix(in srgb, var(--accent-success), transparent 90%); // rgba(var(--accent-primary), 90%);
    border-radius: 4px;
}

.lora-close-button {
    position: absolute;
    inset: 0;
    background-color: #00000080;
    pointer-events: all;
    justify-content: center;
    display: flex;
    opacity: 0;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
}

.ant-input-number-group-addon:has(button) {
    padding: 0 !important;
    border: none !important;
}


.lora-wrapper {
    position: relative;

    .lora-deactivate-button {
        position: absolute;
        inset: 0;
        background-color: #2fcc0a60;
        pointer-events: none;
        justify-content: center;
        display: flex;
        opacity: 1;
        cursor: pointer;
    }

    .lora-hover-cover {
        position: absolute;
        inset: 0;
        opacity: 0;
        background-color: #00000080;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

    .lora-library-thumbnail {
        max-width: 100%;
        height: auto;
        cursor: pointer;
    }

    .lora-steps-counter {
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #00000080;
        font-size: 11px;
        padding: 1px 4px;
    }

    .lora-group-counter {
        pointer-events: none;
        position: absolute;
        bottom: 8px;
        right: 3px;
        width: 19px;
        height: 19px;
        text-align: center;
        color: black;
        font-weight: 500;
        background-color: var(--accent-primary);
        border-radius: 50%;
        font-size: 11px;
        padding: 2px 2px;
    }
}